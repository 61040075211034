<template>
  <div class="check">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="实训名称">
        <el-select v-model="form.id" placeholder="请选择活动">
          <el-option
            v-for="item in sx"
            :key="item.id"
            :label="item.name + '（' + item.jiangshi + '）'"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实训人数">
        <el-input v-model="form.s_shiji" placeholder="实训真实人数"></el-input>
      </el-form-item>
      <el-form-item label="讲师姓名">
        <el-input
          v-model="form.jiangshi"
          placeholder="报名学生的负责老师"
        ></el-input>
      </el-form-item>
      <el-form-item label="学员姓名">
        <el-input
          v-model="form.s_name"
          placeholder="实训报名学生姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input
          v-model="form.s_tel"
          placeholder="实训报名学生电话"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "SXResultAdd",
  data: function () {
    return {
      form: {
        id: "",
        s_shiji: "",
        s_name: "",
        s_tel: "",
        jiangshi: "",
      },
      sx: [],
    };
  },
  async created() {
    //获取实训结果
    let res = await this.$datas.sxCheck;
    this.sx = res.data.data;
  },
  methods: {
    async onSubmit() {
      this.form.s_shiji = parseInt(this.form.s_shiji);
      this.$datas.meta = { form: this.form };
      // 添加报名
      let res = await this.$datas.bmAdd;
      if (res.status < 300 && res.status >= 200) {
        this.$alert("学生信息添加成功", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
  },
};
</script>
<style lang="less">
.check {
  width: 70%;
  padding: 30px 0;
  .el-select {
    width: 80%;
  }
}
</style>
